import styled, { css } from 'styled-components';
import { mediaTablet } from 'utils/responsive';

export default styled.div`
  height: 5vh;
  ${mediaTablet(css`
    height: 7vh;
  `)}
  color: ${({ theme }) => theme.colors.primary.light};
  position: absolute;
  top: 7vh;
  left: 5%;
  z-index: 1;
  transform: translateY(-50%);
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  &:hover {
    opacity: 0.5;
  }
  transition: opacity 0.2s ease-out;
`;
