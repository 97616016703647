import Wrapper from 'components/FilmPage/HeaderLogo/styled/Wrapper';
import Logo from 'components/Icons/Logo';
import CallBackFunc from 'interface/callbackFunc';
import React from 'react';

interface Props {
  onClick?: CallBackFunc<void, void>;
  color?: string;
}

const HeaderLogo = ({ onClick, color }: Props) => (
  <Wrapper onClick={onClick}>
    <Logo color={color} />
  </Wrapper>
);

export default HeaderLogo;
