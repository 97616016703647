import styled, { css } from 'styled-components';
import {
  landscape,
  mediaDesktop,
  mediaTablet,
  mediaTabletAndPortrait,
} from 'utils/responsive';

export const NexaBlack = css`
  font-family: Nexa Black, sans-serif;
  font-weight: 900;
`;

export const Arapey = css`
  font-family: Arapey, sans-serif;
  font-weight: 400;
`;

export const MadeBonVoyage = css`
  font-family: MADE Bon Voyage, sans-serif;
  font-weight: 400;
`;

export const MadeBonVoyageThin = css`
  font-family: MADE Bon Voyage, sans-serif;
  font-weight: 250;
`;

export const SmallText = styled.p`
  margin: 0;
  font-size: 4.5vw;
  color: ${({ theme, light }) =>
    light ? theme.colors.primary.light : theme.colors.primary.dark};
  ${mediaTablet(css`
    font-size: 2.2vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 4vw;
  `)}
  ${mediaDesktop(css`
    font-size: 1vw;
    font-size: max(1vw, 18px);
  `)}
`;

const MainHeaderStyle = css`
  ${MadeBonVoyage};
  white-space: pre-line;
  font-size: 13vw;
  line-height: 11.5vw;
  ${mediaDesktop(css`
    font-size: 7.5vw;
    line-height: 6vw;
    font-size: max(7.5vw, 100px);
    line-height: max(6vw, 80px);
  `)};
  margin: 0;
  b {
    ${NexaBlack};
  }
  i {
    font-style: normal;
    ${MadeBonVoyageThin};
  }
`;

export const H1 = styled.h1`
  ${MainHeaderStyle}
`;

export const H2 = styled.h1`
  ${MainHeaderStyle}
`;

export const H3 = styled.h3`
  ${MainHeaderStyle};

  font-size: 8vw;
  line-height: 7.5vw;

  ${mediaTablet(css`
    font-size: 6vw;
    line-height: 6.6vw;
  `)}

  ${landscape} {
    font-size: 4vw;
    line-height: 4.6vw;
  }

  ${mediaDesktop(css`
    font-size: 2.5vw;
    line-height: 2.6vw;
  `)}
`;

export const Paragraph1 = styled(SmallText)`
  ${Arapey};
  margin: 0;
  font-size: 4.5vw;
  color: ${({ theme, light }) =>
    light ? theme.colors.primary.light : theme.colors.primary.dark};
  ${mediaTablet(css`
    font-size: 2.2vw;
  `)};
  ${mediaTabletAndPortrait(css`
    font-size: 4vw;
  `)}
  ${mediaDesktop(css`
    font-size: 1.5vw;
  `)}
`;

export const MetadataBold = styled(SmallText)`
  ${NexaBlack};
  white-space: pre-line;
  margin: 0 0 0 0;
  ${mediaDesktop(css`
    margin: 0 0 5% 0;
  `)}
`;

export const MetadataItalic = styled(SmallText)`
  ${Arapey};
  font-style: italic;
  margin: 0;
`;
