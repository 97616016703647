export default {
  colors: {
    primary: {
      dark: '#252525',
      light: '#EEEEEE',
    },
    black: '#101010',
    white: '#ffffff',
    lightGray: '#bdbdbd',
    lightGray2: '#d7d7d7',
    lightGray3: '#c4c4c4',
    transparent: 'rgba(0, 0, 0, 0)',
  },
  fonts: {
    arapeyItalic: 'Arapey-italic',
    arapey: 'Arapey-regular',
    made: 'MADE-Bon-Voyage-Regular',
    madeThin: 'MADE-Bon-Voyage-Thin',
    nexaBlack: 'NexaBlack',
  },
};
